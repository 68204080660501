import _core from "./lib/core";
import _async from "./lib/async";
import _sync from "./lib/sync";
var exports = {};
var core = _core;
exports = exports = _async;
exports.core = core;

exports.isCore = function (x) {
  return core[x];
};

exports.sync = _sync;
export default exports;
export const isCore = exports.isCore,
      sync = exports.sync;
const _core2 = exports.core;
export { _core2 as core };